// import React from 'react'
// import { Link } from 'react-router-dom'
// import './Nav.css';
// import { HashLink } from 'react-router-hash-link';
// const Navbar = () => {
//   return (
//     <div className='Home'>
//      <HashLink> CodingShark</HashLink>    
// <Link className='li' to="#/">Home</Link>
// <Link className='li' to="#About">About</Link>
// <Link className='li' to="#Contact">Contact</Link>
// <Link className='li' to="#Brands">Brands</Link>
// <Link className='li' to="#Services">Services</Link>




//     </div>
//   )
// }

// export default Navbar


import React from 'react'
import { NavLink } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import './Nav.css';


const Navbar = () => {
    return (
        <nav className='nav'>
            <div className='logo'>
                <HashLink to="/#Home">codingshark</HashLink>
                </div>
            <div className='nav-links'>
                <li type='none'><HashLink  to="/#Home">HOME</HashLink></li>
                <li type='none'><HashLink smooth to="/#About">ABOUT</HashLink></li>
                <li type='none'><HashLink smooth to="/#Brands">BRANDS</HashLink></li>
                <li type='none'><NavLink to="/Contact">CONTACT</NavLink></li>
                <li type='none'><NavLink to="/Services">SERVICES</NavLink></li>
                
            </div>
        
        </nav>
       
    )
}

export default Navbar