// import React from 'react'
// const Home = () => {
//   return (
//     <div>
      
       



        
//     </div>
//   )
// }

// export default Home
import React from 'react'
import './Home.css';
import {AiFillGoogleCircle , AiFillInstagram ,AiFillAmazonCircle} from 'react-icons/ai'
import {SiFlipkart} from 'react-icons/si'
import Footer from './Footer';




const Home = () => {
  return (
   <div id='Home'>
    <div className='home1' >
      <div className='home1-inner'>
      <h1>CODINGSHARK</h1>
      <p>Solution for all queries</p>
      </div>
    </div>
    <main className='main'>
    <div className='home2' id='About'>
      <h1 id='About'>ABOUT</h1>
      <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Est iure,
         at quo magni quae odit, eius dolore velit delectus amet, similique quis 
         eveniet odio illo obcaecati! Nemo eum temporibus tempora!</p>
         <button className='btn'>Read more...</button>
    </div>
    </main>
    <div className='home3' id='Brands'>
      <div className='home3-inner'>
        <h1>Brands</h1>
        <div className="brands">
          <div className="brand" style={{animationDelay:"0.25s"}}>
          <AiFillAmazonCircle className='icons'/>
          <p>Amazon</p>
          </div>
          <div className="brand" style={{animationDelay:"0.50s"}}>
          <AiFillInstagram className='icons'/>
          <p>Instagram</p>
          </div>
          <div className="brand" style={{animationDelay:"0.75s"}}>
          <AiFillGoogleCircle className='icons' />
          <p>Google</p>
          </div>
          <div className="brand" style={{animationDelay:"1s"}}>
          <SiFlipkart className='icons' />
          <p>Flipkart</p>
          </div>
        </div>
      </div>
    </div>
    <Footer/>
   
  
   </div>
   
  )
}

export default Home
