
import './App.css';

import { BrowserRouter } from 'react-router-dom';
import Navbar from './components/Navbar';
import { Routes } from 'react-router-dom';
import { Route } from 'react-router-dom';
import Home from './components/Home';
import About from './components/About';
import Contact from './components/Contact';
import Brands from './components/Brands';
import Services from './components/Services';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
<Navbar/>
<Routes>
<Route path="/" element={<Home/>}/>
<Route path="/About" element={<About/>}/>
<Route path="/Contact" element={<Contact/>}/>
<Route path="/Brands" element={<Brands/>}/>
<Route path="/Services" element={<Services/>}/>


</Routes>
      </BrowserRouter>
      
     
    </div>
  );
}

export default App;
