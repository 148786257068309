// import React from 'react'

// const Services = () => {
//   return (
//     <div>Services</div>
//   )
// }

// export default Services



import React from 'react'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import './Services.css'
const Services = () => {
  return (
    <div className="services">
      <Carousel infiniteLoop={true} autoPlay={true} showStatus={false} showArrows={false} showThumbs={false} interval={3000}
       transitionTime={1000} >
        <div>
          <img src="\but.jpg" alt="" />
          <p className='legend'>industry</p>
        </div>
        <div>
          <img src="\a.jpg" alt="" />
          <p className='legend'>metro</p>
        </div>
        <div>
          <img src="\b.jpg" alt="" />
          <p className='legend'>river</p>
        </div>
        <div>
          <img src="\c.jpg" alt="" />
          <p className='legend'>water</p>
        </div>

      </Carousel>
    </div>
  )
}

export default Services
